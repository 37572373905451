import { call, delay, put, select, takeLeading } from "redux-saga/effects";

import {
  AuthPayload,
  BuildingPayload,
  BuyPayload,
  CharacterData,
  EnhancePayload,
  FightState,
  GetItemPayload,
  LoseItemPayload,
  NewCharPayload,
  ResetPasswordPayload,
  SkillModel,
  SwitchPilotPayload,
  SwitchShipPayload,
  TrainPayload,
} from "types";

import { User } from "firebase/auth";
import {
  calculateLevelsGained,
  calculateUpdatedLevelExp,
  getSwitchCost,
} from "libs/character";
import { getStatPointsGained } from "libs/fight";
import { getInventoryItemAmount, getItemData } from "libs/item";
import { getSkillsResetCost } from "libs/skill";
import {
  getAreaData,
  getBaseStatData,
  getPilotData,
  getPropertyData,
  getShipData,
  getStatsResetCost,
  MAX_BASE_STAT_VALUE,
} from "libs/stats";
import {
  addEventLog,
  addLogMessage,
  buyEnhanceStat,
  buyRepair,
  buyResetSkills,
  buyResetStats,
  buyRestore,
  buyShopItem,
  buySwitchPilot,
  buySwitchShip,
  buyTrainSkill,
  changePlanetPlaylist,
  clearCharacterState,
  clearFightState,
  continueAsCharacter,
  continueFight,
  createCharacter,
  disableMovement,
  enableMovement,
  enhanceStat,
  enterArea,
  enterBuilding,
  exitBuilding,
  exitToMainMenu,
  gainCredits,
  gainHealth,
  hideAuthOverlay,
  hideInsideBuilding,
  hideWorld,
  levelUp,
  linkGoogleAccount,
  loadUserData,
  loseCredits,
  move,
  moveLeft,
  moveRight,
  resetPassword,
  resetSkills,
  resetStats,
  restoreAllParts,
  sellShopItem,
  setAuthOverlayScreen,
  setFightResults,
  setGameModeActive,
  setGameModeInactive,
  setHealth,
  setInitialCharacterState,
  setInitialDataLoaded,
  setInitialFightState,
  setLevelExp,
  setMainMenuScreen,
  setNearBoundary,
  setNewCharacterScreen,
  setNewUser,
  setPilotProfession,
  setShipClass,
  setUserAccountLinked,
  setUserAccountUnlinked,
  setUserId,
  setUserName,
  showInsideBuilding,
  showMessage,
  showWorld,
  signInEmailAccount,
  signInGoogleAccount,
  signOutUserAccount,
  signUpEmailAccount,
  trainSkill,
  validateCharacterName,
} from "redux/actions";
import { getCharacter, getGameState } from "redux/selectors";
import {
  claimUserName,
  createNewCharacter,
  getErrorMessage,
  getExistingUserName,
  linkEmailUserAccount,
  linkGoogleUserAccount,
  loadUser,
  sendResetPasswordEmail,
  setOnlineStatus,
  setUserOffline,
  signInAnonymousUser,
  signInEmailUser,
  signInGoogleUser,
  signOutUser,
  signUpEmailUserAccount,
} from "utils/server/firebase";
import { getOnlineUsersSaga, setTitleScreenPlaylistSaga } from "./game";
import { adjustUpgradesSaga, getItemsSaga, loseItemsSaga } from "./item";

export const BOUNDARY_RANGE = 25;

function* loadUserDataSaga({ payload: userAuth }: { payload: User | null }) {
  // If no auth exists, it's a new user
  if (!userAuth) {
    yield put(setNewUser(true));
    // Allow title screen to show options
    yield put(setInitialDataLoaded());
    return;
  }

  // If already authenticated (ex: linking Google account)
  // Don't load data or change state
  const { userId } = yield select(getCharacter);
  if (userId) {
    console.log("User data already loaded");
    // Allow title screen to show options
    yield put(setInitialDataLoaded());
    return;
  }

  // Set user ID in state to set that auth exists
  yield put(setUserId(userAuth.uid));

  const userData: {
    userName: string;
    characterData: CharacterData;
    fightData: FightState;
  } | null = yield loadUser(userAuth.uid);

  if (!userData) {
    // Existing auth but no existing user - create character and link
    // This should only happen with Google
    if (!userAuth.isAnonymous) {
      console.log("No existing user, but user auth exists");
    }
    // Allow title screen to show options
    yield put(setInitialDataLoaded());
    return;
  }

  // Set if user has an account linked already
  if (!userAuth.isAnonymous) {
    yield put(setUserAccountLinked());
  }

  // Hide new character form to show something is happening
  yield put(setNewUser(false));

  // Set online status to true
  yield setOnlineStatus(userAuth.uid);

  // Load initial list of online players
  yield call(getOnlineUsersSaga);

  // Load the rest of the data and start the game
  yield put(setUserName(userData.userName));
  yield put(setInitialCharacterState(userData.characterData));
  yield put(setInitialFightState(userData.fightData));

  // Allow title screen to show options
  yield put(setInitialDataLoaded());
}

function* validateCharacterNameSaga({
  payload: userName,
}: {
  payload: string;
}) {
  // Character Name Validation

  // Validation: Minimum 3 characters
  if (userName.length < 3) {
    yield put(showMessage("Your name should be at least 3 characters long"));
    return;
  }

  // Validation: Alphabet only
  var alphabet = /^[A-Za-z]+$/;
  if (!userName.match(alphabet)) {
    yield put(showMessage("Your name can only contain letters"));
    return;
  }

  // Validation: Check if user name exists in Firebase
  const isUserNameTaken: boolean = yield getExistingUserName(userName);

  if (isUserNameTaken) {
    yield put(showMessage("This name is already being used"));
    return;
  }

  // If validation passes, move to final new character screen
  yield put(setNewCharacterScreen("saveCharacter"));
}

function* createCharacterSaga({ payload }: { payload: NewCharPayload }) {
  const { ship, pilot, userName } = payload;

  // Create New Character

  // No longer a new user with no auth
  yield put(setNewUser(false));

  // If there's already a user auth (user ID exists in state),
  // leave user signed in

  // Get User ID
  let { userId } = yield select(getCharacter);

  if (!userId) {
    // If no auth exists, sign in anonymously
    const { uid } = yield signInAnonymousUser();
    userId = uid;
  } else {
    // If auth already exist, consider the account linked
    yield put(setUserAccountLinked());
  }

  try {
    // Create new character in Firebase
    yield claimUserName(userId, userName);
    yield createNewCharacter(userId, userName);

    // Set online status to true
    yield setOnlineStatus(userId);

    // Load initial list of online players
    yield call(getOnlineUsersSaga);

    // Set selected name, ship, and pilot in state
    yield put(setUserName(userName));
    yield put(setShipClass(ship));
    yield put(setPilotProfession(pilot));

    // Initial full heal
    yield call(healSaga);

    // Start saving character data to Firebase
    yield put(setGameModeActive());

    // Set planet-specific playlist
    yield put(changePlanetPlaylist());

    yield put(showMessage(`Welcome, ${userName}!`));

    // Add to achievements log
    yield put(addLogMessage(`Welcome to Bishop City, ${userName}!`));

    yield put(addEventLog({ event: "create_new_character" }));
  } catch (error: any) {
    console.error("Error in creating new character", error.message);
    return;
  }
}

function* continueAsCharacterSaga() {
  const { userId, userName } = yield select(getCharacter);

  yield setOnlineStatus(userId);
  yield put(setGameModeActive());
  yield put(showMessage(`Welcome back, ${userName}!`));
  yield put(continueFight());
  yield put(changePlanetPlaylist());

  yield put(addEventLog({ event: "continue_game" }));
}

// Auth Sagas

function* signInGoogleAccountSaga(): any {
  const { isNewUser } = yield select(getGameState);

  if (!!isNewUser) {
    try {
      const user = yield signInGoogleUser();
      const { uid: userId } = user;

      yield call(signInSuccessfulSaga, userId);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error.code);
      yield put(showMessage(errorMessage));
      console.error("Error in signInGoogleUser", error.message, errorMessage);
    }
  } else {
    yield put(showMessage("Action not allowed"));
    console.error("signInGoogleAccountSaga not allowed");
  }
}

function* linkGoogleAccountSaga() {
  const { userName } = yield select(getCharacter);
  const { isNewUser, isUserAccountLinked } = yield select(getGameState);

  if (!isNewUser && !isUserAccountLinked && !!userName) {
    try {
      yield linkGoogleUserAccount();

      yield call(linkSuccessfulSaga);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error.code);
      yield put(showMessage(errorMessage));
      console.error(
        "Error in linkGoogleUserAccount",
        error.message,
        errorMessage
      );
    }
  } else {
    yield put(showMessage("Action not allowed"));
    console.error("linkGoogleAccountSaga not allowed");
  }
}

function* signInEmailAccountSaga({ payload }: { payload: AuthPayload }) {
  const { isNewUser } = yield select(getGameState);

  if (!!isNewUser) {
    try {
      const { email, password } = payload;
      const { uid: userId } = yield signInEmailUser(email, password);

      yield call(signInSuccessfulSaga, userId);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error.code);
      yield put(showMessage(errorMessage));
      console.error("Error in signInEmailUser", error.message, errorMessage);
    }
  } else {
    yield put(showMessage("Action not allowed"));
    console.error("signInEmailAccountSaga not allowed");
  }
}

function* signUpEmailAccountSaga({ payload }: { payload: AuthPayload }) {
  const { userName } = yield select(getCharacter);
  const { isNewUser, isUserAccountLinked } = yield select(getGameState);

  if (!isNewUser && !isUserAccountLinked && !!userName) {
    try {
      const { email, password } = payload;

      // Figure out whether to link to an existing anonymous user
      // Or create a new Firebase user as well
      let { isNewUser } = yield select(getGameState);
      if (!!isNewUser) {
        // No auth user exists, anonymous or account
        // Create Firebase user and credential (account auth)
        const { uid: userId } = yield signUpEmailUserAccount(email, password);
        yield call(signUpSuccessfulSaga, userId);
      } else {
        // Anonymous user already exists, link new account credential to it
        yield linkEmailUserAccount(email, password);
        yield call(linkSuccessfulSaga);
      }
    } catch (error: any) {
      const errorMessage = getErrorMessage(error.code);
      yield put(showMessage(errorMessage));
      console.error(
        "Error in signUpEmailAccountSaga",
        error.message,
        errorMessage
      );
    }
  } else {
    yield put(showMessage("Action not allowed"));
    console.error("signUpEmailAccountSaga not allowed");
  }
}

function* signInSuccessfulSaga(userId: string) {
  // Set user ID in state to set that auth exists
  yield put(setUserId(userId));

  // No longer a new user with no auth
  yield put(setNewUser(false));

  yield put(setUserAccountLinked());

  yield put(hideAuthOverlay());

  yield put(showMessage(`Log in successful!`));
}

function* signUpSuccessfulSaga(userId: string) {
  // Set user ID in state to set that auth exists
  yield put(setUserId(userId));

  // No longer a new user with no auth
  yield put(setNewUser(false));

  yield put(setUserAccountLinked());

  yield put(hideAuthOverlay());

  yield put(showMessage(`Sign up successful!`));

  yield put(addEventLog({ event: "link_account" }));
}

function* linkSuccessfulSaga() {
  yield put(setUserAccountLinked());
  yield put(showMessage(`Linked successfully!`));
  yield put(hideAuthOverlay());

  yield put(addEventLog({ event: "link_account" }));
}

function* signOutUserAccountSaga() {
  const { userId } = yield select(getCharacter);
  const { isUserAccountLinked } = yield select(getGameState);

  try {
    // Set user as offline in Firebase
    yield setUserOffline(userId);

    // Firebase user auth sign out,
    yield signOutUser();

    // Stop saving to Firebase, show main menu
    yield put(setMainMenuScreen("title"));
    yield put(setGameModeInactive());

    // Add event log before character state is cleared
    yield put(
      addEventLog({
        event: "sign_out",
        eventParams: {
          isAccountLinked: isUserAccountLinked,
        },
      })
    );

    // Clear out character state
    yield put(clearCharacterState());

    // Clear out fight state
    yield put(clearFightState());

    // Set user account as unlinked
    yield put(setUserAccountUnlinked());

    // Set as new user, since there's no character set
    yield put(setNewUser(true));

    // Change playlist to title screen list
    yield call(setTitleScreenPlaylistSaga);

    yield put(showMessage(`You have been logged out`));
  } catch (error: any) {
    console.error("Error in signOutUser", error.message);
    return;
  }
}

function* resetPasswordSaga({ payload }: { payload: ResetPasswordPayload }) {
  try {
    const { email } = payload;
    yield sendResetPasswordEmail(email);

    yield put(showMessage("Reset Password Email has been sent!"));
    yield put(setAuthOverlayScreen("login"));
  } catch (error: any) {
    const errorMessage = getErrorMessage(error.code);
    yield put(showMessage(errorMessage));
    console.error(
      "Error in sendResetPasswordEmail",
      error.message,
      errorMessage
    );
  }
}

function* exitToMainMenuSaga() {
  const { userId } = yield select(getCharacter);

  try {
    // Set user as offline
    yield setUserOffline(userId);

    // Stop saving to Firebase, show title screen
    yield put(setMainMenuScreen("title"));
    yield put(setGameModeInactive());

    // Change playlist to title screen list
    yield call(setTitleScreenPlaylistSaga);
  } catch (error: any) {
    console.error("Error in exitToMainMenu", error.message);
    return;
  }
}

// Movement/Location Sagas

export function* moveSaga({ payload: direction }: { payload: string }): any {
  const {
    data: {
      location,
      derivedStats,
      ui: { isMovementDisabled },
    },
  } = yield select(getCharacter);

  if (isMovementDisabled) {
    return;
  }

  const areaData = getAreaData(location.area);
  switch (direction) {
    case "left":
      // Make sure player won't go to negative position, with a bigger movement speed
      if (location.position >= derivedStats.current.complete.movementSpeed) {
        yield put(moveLeft(derivedStats.current.complete.movementSpeed));

        // If near boundary of area, send exit message
        if (location.position < BOUNDARY_RANGE) {
          if (!!areaData.areaLeft) {
            if (!location.isNearBoundary) {
              yield put(setNearBoundary(true));
              yield put(showMessage(`Leaving ${areaData.name}`));
            }
          }
        } else {
          if (!!location.isNearBoundary) {
            yield put(setNearBoundary(false));
          }
        }
      } else {
        // Enter new area (if exists) to left of current area
        if (!!areaData.areaLeft) {
          const areaLeftData = getAreaData(areaData.areaLeft);
          yield call(
            enterAreaSaga,
            areaData.areaLeft,
            areaLeftData.moveRange,
            direction
          );
        }
      }

      break;
    case "right":
      // Make sure player won't go beyond right boundary with a bigger movement speed
      if (
        location.position + derivedStats.current.complete.movementSpeed <=
        areaData.moveRange
      ) {
        yield put(moveRight(derivedStats.current.complete.movementSpeed));

        // If near boundary of area, send exit message
        if (location.position + BOUNDARY_RANGE >= areaData.moveRange) {
          if (!!areaData.areaRight) {
            if (!location.isNearBoundary) {
              yield put(setNearBoundary(true));
              yield put(showMessage(`Leaving ${areaData.name}`));
            }
          }
        } else {
          // Reset boundary state
          if (!!location.isNearBoundary) {
            yield put(setNearBoundary(false));
          }
        }
      } else {
        // Enter new area (if exists) to right of current area
        if (!!areaData.areaRight) {
          yield call(enterAreaSaga, areaData.areaRight, 0, direction);
        }
      }
      break;
    default:
      break;
  }
}

export function* enterAreaSaga(
  area: string,
  position: number,
  direction: string,
  building?: string,
  buildingScreen?: string,
  buildingDialog?: string
) {
  yield put(disableMovement());

  yield put(hideWorld());

  const areaData = getAreaData(area);
  yield put(showMessage(`Entering ${areaData.name}`));

  yield delay(500);

  yield put(
    enterArea({ area: area, position: position, direction: direction })
  );

  // Send directly to building if specified
  if (building) {
    yield put(
      enterBuilding({
        building: building,
        screen: buildingScreen || null,
        dialog: buildingDialog || null,
      })
    );
  }

  yield delay(750);

  yield put(showWorld());

  yield put(enableMovement());
}

function* enterBuildingSaga({ payload }: { payload: BuildingPayload }) {
  const { building, screen = null, dialog = null } = payload;

  yield put(showInsideBuilding({ building, screen, dialog }));

  yield put(disableMovement());
}

function* exitBuildingSaga() {
  yield put(hideInsideBuilding());

  yield put(enableMovement());
}

function* buyRepairSaga({ payload }: { payload: BuyPayload }) {
  const {
    data: { credits, health, derivedStats },
  } = yield select(getCharacter);

  // Check if character has enough credits to pay
  if (credits < payload.credits) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Check if already fully healed
  if (health >= derivedStats.current.complete.maxHealth) {
    yield put(showMessage(`Your ship is already fully repaired, chill out`));
    return;
  }

  // Heal specified amount of health
  yield call(healSaga);

  // Subtract credits
  yield call(loseCreditsSaga, payload.credits);

  yield put(showMessage(`Your ship has been fully repaired`));
}

export function* healSaga(healAmount?: number): any {
  const {
    data: { health, derivedStats },
  } = yield select(getCharacter);
  const currentMaxHealth = derivedStats.current.complete.maxHealth;

  // If max health is lower than current health, don't heal
  if (health > currentMaxHealth) {
    return;
  }

  // If no amount is passed through, heal to max health
  const healthGained = healAmount || currentMaxHealth;

  // Don't heal past max health
  const totalHealthGained = Math.min(currentMaxHealth - health, healthGained);

  yield put(gainHealth(totalHealthGained));
}

export function* adjustHealthSaga(): any {
  // Adjust health to new maxHealth, if maxHealth is lower
  const {
    data: { health, derivedStats },
  } = yield select(getCharacter);
  const currentMaxHealth = derivedStats.current.complete.maxHealth;

  if (health > currentMaxHealth) {
    yield put(setHealth(currentMaxHealth));
  }
}

function* buyRestoreSaga({ payload }: { payload: BuyPayload }) {
  const {
    data: { credits },
  } = yield select(getCharacter);

  // Check if character has enough credits to pay
  if (credits < payload.credits) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Restore all weakened parts
  yield put(restoreAllParts());

  // Subtract credits
  yield call(loseCreditsSaga, payload.credits);
}

function* buyEnhanceStatSaga({ payload }: { payload: EnhancePayload }) {
  const {
    data: { totalBaseStats, medallions, ship },
  } = yield select(getCharacter);

  // Don't let base stats go beyond max value
  if (totalBaseStats[payload.baseStat] >= MAX_BASE_STAT_VALUE) {
    const baseStatInfo = getBaseStatData(payload.baseStat);
    yield put(showMessage(`You're already at max ${baseStatInfo.name}`));
    return;
  }

  // Get token cost
  const tokenCost = getShipData(ship).baseStatsCosts[payload.baseStat];

  // Check if character has enough tokens to pay
  if (medallions.available < tokenCost) {
    yield put(showMessage(`You don't have enough medallions, maybe get more`));
    return;
  }

  // Increase chosen base stat by 1
  yield put(enhanceStat(payload.baseStat));

  yield put(
    addEventLog({
      event: "enhance_stat",
      eventParams: { stat: payload.baseStat },
    })
  );
}

function* buyResetStatsSaga() {
  const {
    data: { medallions, credits },
  } = yield select(getCharacter);

  // Get credits cost of reset stats (mechanical engineers reset for free)
  const resetCost = getStatsResetCost(medallions.statsSpent);

  // Check if character has enough credits to pay
  if (credits < resetCost) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Reset base stats and give back medallions and downstream effects
  yield call(resetStatsSaga);

  // Subtract credits
  yield call(loseCreditsSaga, resetCost);

  yield put(addEventLog({ event: "reset_stats" }));
}

function* resetStatsSaga() {
  // Reset all enhanced base stats
  yield put(resetStats());

  // Adjust health to new maxHealth, if maxHealth is lower
  yield call(adjustHealthSaga);

  yield put(showMessage(`You have reset your medallions`));

  // Uninstall upgrades that no longer meet base stat requirements
  yield call(adjustUpgradesSaga);
}

function* buySwitchShipSaga({ payload }: { payload: SwitchShipPayload }) {
  const {
    data: { ship: currentShip, level, credits },
  } = yield select(getCharacter);
  const newShip = payload.ship;
  const currentShipInfo = getShipData(currentShip);
  const newShipInfo = getShipData(newShip);

  // Get credits cost of switch
  const switchCost = getSwitchCost(level);

  // Check if character has enough credits to pay
  if (credits < switchCost) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Reset base stats and give back medallions and downstream effects
  // IMPORTANT: This needs to happen before the switch to a new ship, otherwise
  // too many/too little medallions are returned
  yield call(resetStatsSaga);

  // Switch to new starship class
  yield put(setShipClass(newShip));

  // Adjust health to new maxHealth again, if maxHealth is lower
  // New class has different resilience
  yield call(adjustHealthSaga);

  // Full heal
  yield call(healSaga);

  // Subtract credits
  yield call(loseCreditsSaga, switchCost);

  yield put(
    showMessage(
      `You have switched your ship from ${currentShipInfo.name} to ${newShipInfo.name}`
    )
  );

  yield put(addEventLog({ event: "switch_ship" }));
}

function* buySwitchPilotSaga({ payload }: { payload: SwitchPilotPayload }) {
  const {
    data: { pilot: currentPilot, level, credits },
  } = yield select(getCharacter);
  const newPilot = payload.pilot;
  const currentPilotInfo = getPilotData(currentPilot);
  const newPilotInfo = getPilotData(newPilot);

  // Get credits cost of switch
  const switchCost = getSwitchCost(level);

  // Check if character has enough credits to pay
  if (credits < switchCost) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Reset skills and give back medallions and downstream effects
  // IMPORTANT: This needs to happen before the switch to a new pilot, otherwise
  // too many/too little medallions are returned
  yield call(resetSkillsSaga);

  // Switch to new starship class
  yield put(setPilotProfession(newPilot));

  // Subtract credits
  yield call(loseCreditsSaga, switchCost);

  yield put(
    showMessage(
      `You have switched your pilot from ${currentPilotInfo.name} to ${newPilotInfo.name}`
    )
  );

  yield put(addEventLog({ event: "switch_pilot" }));
}

function* buyTrainSkillSaga({ payload }: { payload: TrainPayload }) {
  const {
    data: { medallions, skills },
  } = yield select(getCharacter);

  // Get skill cost
  const skillData = skills.find(
    (skill: SkillModel) => skill.slug === payload.skill
  );
  const tokenCost = skillData.medallions;

  // Check if already trained skill
  if (!!skillData.isTrained) {
    yield put(
      showMessage(
        `You've already trained that skill. Stop trying so hard, nerd.`
      )
    );
    return;
  }

  // Check if character has enough tokens to pay
  if (medallions.available < tokenCost) {
    yield put(showMessage(`You don't have enough medallions, maybe get more`));
    return;
  }

  // Add skill to character's learned skills
  yield put(trainSkill(payload.skill));

  yield put(showMessage(`You have learned ${skillData.name}`));

  yield put(
    addEventLog({
      event: "train_skill",
      eventParams: { skill: payload.skill },
    })
  );
}

function* buyResetSkillsSaga() {
  const {
    data: { medallions, credits },
  } = yield select(getCharacter);

  // Get credits cost of reset
  const resetCost = getSkillsResetCost(medallions.skillsSpent);

  // Check if character has enough credits to pay
  if (credits < resetCost) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  // Reset skills and give back medallions
  yield call(resetSkillsSaga);

  // Subtract credits
  yield call(loseCreditsSaga, resetCost);

  yield put(addEventLog({ event: "reset_skills" }));
}

function* resetSkillsSaga() {
  // Reset all skills
  yield put(resetSkills());

  yield put(showMessage(`You have reset your medallions`));
}

export function* buyShopItemSaga({ payload }: { payload: GetItemPayload }) {
  const {
    data: { credits },
  } = yield select(getCharacter);

  // Get supply or upgrade data
  const propertyInfo = getPropertyData("credits");
  const itemData = getItemData(payload.slug);
  const itemCost = propertyInfo.rounder(itemData.credits * payload.quantity);

  // Check if player has enough credits
  if (credits < itemCost) {
    yield put(showMessage(`You don't have enough credits, maybe get more`));
    return;
  }

  try {
    // Gain supplies or upgrades - move to array
    yield call(getItemsSaga, { payload: [payload] });

    // Subtract credits
    yield call(loseCreditsSaga, itemCost);

    yield put(showMessage(`You bought ${payload.quantity} ${itemData.name}`));
  } catch (error: any) {}
}

export function* sellShopItemSaga({ payload }: { payload: LoseItemPayload }) {
  const {
    data: { inventory },
  } = yield select(getCharacter);

  // Check inventory for item
  const itemAmount = getInventoryItemAmount(payload.slug, inventory);

  // Check if player has enough items in their inventory
  if (itemAmount < payload.quantity) {
    yield put(showMessage(`You don't have enough in your inventory to sell`));
    return;
  }

  // Get supply or upgrade data
  const propertyInfo = getPropertyData("credits");
  const itemData = getItemData(payload.slug);
  const itemCost = propertyInfo.rounder(
    itemData.sellCredits * payload.quantity
  );

  // Lose supplies or upgrades - move to array
  yield call(loseItemsSaga, { payload: [payload] });

  // Subtract credits
  yield call(gainCreditsSaga, itemCost);

  yield put(showMessage(`You sold ${payload.quantity} ${itemData.name}`));
}

export function* gainExpSaga(expGained: number, fromSupply?: boolean): any {
  // Calculate if newly gained exp is enough for a level up
  const {
    data: { levelExp },
  } = yield select(getCharacter);

  const levelsGained = calculateLevelsGained(levelExp, expGained);

  if (levelsGained > 0) {
    yield call(levelUpSaga, levelsGained, fromSupply);
  }

  // Set updated level exp for current or new level
  const updatedLevelExp = calculateUpdatedLevelExp(levelExp, expGained);
  yield put(setLevelExp(updatedLevelExp));
}

export function* levelUpSaga(levelsGained: number, fromSupply?: boolean): any {
  // Update to new level
  yield put(levelUp(levelsGained));

  const {
    userName,
    data: { level },
  } = yield select(getCharacter);

  // Calculate and add base stat points to spend - handle multiple levels
  const statPointsGained = getStatPointsGained(level, levelsGained);

  if (fromSupply) {
    // Exp gained from supply
    yield put(
      showMessage(
        `Level up! You are now ${level}! You've also earned ${statPointsGained} medallions`
      )
    );
  } else {
    // Exp gained from fight
    yield put(
      setFightResults({
        isLevelUp: true,
        statPoints: statPointsGained,
      })
    );
  }

  // Add to achievements log
  yield put(addLogMessage(`Level up! ${userName} is now level ${level}!`));

  yield put(addEventLog({ event: "level_up" }));
}

export function* gainCreditsSaga(creditsGained: number): any {
  // Add credits
  yield put(gainCredits(creditsGained));
}

export function* loseCreditsSaga(creditsLost: number): any {
  const {
    data: { credits },
  } = yield select(getCharacter);

  // Don't let credits amount go below 0
  const totalCreditsLost = Math.min(credits, creditsLost);

  // Lose credits
  yield put(loseCredits(totalCreditsLost));
}

export default function* characterSagas() {
  yield takeLeading(validateCharacterName, validateCharacterNameSaga);
  yield takeLeading(createCharacter, createCharacterSaga);
  yield takeLeading(loadUserData, loadUserDataSaga);
  yield takeLeading(continueAsCharacter, continueAsCharacterSaga);
  yield takeLeading(signInGoogleAccount, signInGoogleAccountSaga);
  yield takeLeading(linkGoogleAccount, linkGoogleAccountSaga);
  yield takeLeading(signInEmailAccount, signInEmailAccountSaga);
  yield takeLeading(signUpEmailAccount, signUpEmailAccountSaga);
  yield takeLeading(signOutUserAccount, signOutUserAccountSaga);
  yield takeLeading(resetPassword, resetPasswordSaga);
  yield takeLeading(exitToMainMenu, exitToMainMenuSaga);
  yield takeLeading(move, moveSaga);
  yield takeLeading(enterBuilding, enterBuildingSaga);
  yield takeLeading(exitBuilding, exitBuildingSaga);
  yield takeLeading(buyRepair, buyRepairSaga);
  yield takeLeading(buyRestore, buyRestoreSaga);
  yield takeLeading(buyEnhanceStat, buyEnhanceStatSaga);
  yield takeLeading(buyResetStats, buyResetStatsSaga);
  yield takeLeading(buyTrainSkill, buyTrainSkillSaga);
  yield takeLeading(buyResetSkills, buyResetSkillsSaga);
  yield takeLeading(buySwitchShip, buySwitchShipSaga);
  yield takeLeading(buySwitchPilot, buySwitchPilotSaga);
  yield takeLeading(buyShopItem, buyShopItemSaga);
  yield takeLeading(sellShopItem, sellShopItemSaga);
}
