import { useSelector } from "react-redux";
import { COLORS } from "utils/constants";

import medallionsImage from "assets/enhancements.svg";
import Tooltip, {
  TooltipDesc,
  TooltipInfo,
  TooltipName,
  TooltipValues,
  ValuesIcon,
} from "components/base/Tooltip";
import { getCharacter } from "redux/selectors";

const TooltipMedallions = () => {
  const {
    data: { medallions },
  } = useSelector(getCharacter);

  return (
    <Tooltip>
      <TooltipName>Medallions</TooltipName>
      <TooltipDesc>
        You use medallions to either strengthen your starship's core attributes
        or learn new pilot skills, at the nearest Shipworks or Pilot Academy in
        any city.
      </TooltipDesc>
      <TooltipDesc>
        You earn medallions when you level up, with more at higher levels.
      </TooltipDesc>
      <TooltipInfo>
        <TooltipValues color={COLORS.ENHANCEMENTS}>
          <ValuesIcon image={medallionsImage} color={COLORS.ENHANCEMENTS} />
          Stat Medallions Spent: {medallions.statsSpent}
        </TooltipValues>
        <TooltipValues color={COLORS.ENHANCEMENTS}>
          <ValuesIcon image={medallionsImage} color={COLORS.ENHANCEMENTS} />
          Skill Medallions Spent: {medallions.skillsSpent}
        </TooltipValues>
      </TooltipInfo>
    </Tooltip>
  );
};

export default TooltipMedallions;
