import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import BackgroundShip from "components/base/BackgroundShip";
import Building from "components/base/Building";
import Mob from "components/base/Mob";
import NPC from "components/base/NPC";
import OnlinePlayer from "components/ui/OnlinePlayer";
import { isFightModeStatus } from "libs/fight";
import { getAreaData } from "libs/stats";
import {
  getCharacter,
  getFight,
  getGameState,
  getOnlinePlayers,
} from "redux/selectors";

interface WorldProps {
  children: React.ReactNode;
}

interface WorldWrapperProps {
  isHidden: boolean;
}

interface FixedBackgroundProps {
  image: string;
}

interface BackgroundLayerProps {
  image: string;
  position: number;
}

interface GameLayerProps {
  position: number;
}

interface FightBackgroundProps {
  isFightMode: boolean;
}

const WorldWrapper = styled.div<WorldWrapperProps>`
  width: 2000vh;
  height: 100vh;
  position: absolute;
  left: 0;
  transition: all 500ms ease-out;
  display: flex;
  align-items: center;
  ${(props) => props.isHidden && `opacity: 0;`}
`;

const FixedWorldLayer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
`;

const WorldLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 0 center;
  pointer-events: none;
  transition: all 200ms ease-out;
`;

const FixedBackgroundLayer = styled(FixedWorldLayer)<FixedBackgroundProps>`
  background-image: url("${(props) => props.image}");
  filter: blur(4px);
`;

const SlowBackgroundLayer = styled(WorldLayer).attrs(
  ({ position }: BackgroundLayerProps) => ({
    style: {
      transform: `translate3d(${-position * 0.005}%, 0, 0)`,
    },
  })
)<BackgroundLayerProps>`
  background-image: url("${(props) => props.image}");
  filter: blur(2px);
`;

const MovingBackgroundLayer = styled(WorldLayer).attrs(
  ({ position }: BackgroundLayerProps) => ({
    style: {
      transform: `translate3d(${-position * 0.1}%, 0, 0)`,
    },
  })
)<BackgroundLayerProps>`
  background-image: url("${(props) => props.image}");
  background-repeat: repeat-x;
`;

const GameLayer = styled.div.attrs(({ position }: GameLayerProps) => ({
  style: {
    transform: `translate3d(${-position * 0.2}%, 0, 0)`,
  },
}))<GameLayerProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 200ms ease-out;
  display: flex;
  align-items: center;
`;

const FightBackground = styled.div<FightBackgroundProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  transition: all 500ms ease-out;
  background-color: #13041900;
  pointer-events: none;
  ${(props) => props.isFightMode && `background-color: #13041980;`}
  ${(props) => props.isFightMode && `pointer-events: auto;`}
`;

const AvatarLayer = styled.div`
  height: 30%;
  margin-left: 20vw;
`;

const ForegroundLayer = styled(WorldLayer).attrs(
  ({ position }: BackgroundLayerProps) => ({
    style: {
      transform: `translate3d(${-position * 0.2}%, 0, 0)`,
    },
  })
)<BackgroundLayerProps>`
  width: 200%;
  background-image: url("${(props) => props.image}");
  background-repeat: repeat-x;
`;

export default function World({ children }: WorldProps) {
  const {
    userName,
    data: {
      location: { position, area },
    },
  } = useSelector(getCharacter);
  const { status } = useSelector(getFight);
  const { isHideWorld } = useSelector(getGameState);
  const onlinePlayers = useSelector(getOnlinePlayers);

  const areaData = getAreaData(area);

  const isFightMode = isFightModeStatus(status);

  return (
    <WorldWrapper isHidden={isHideWorld}>
      <FixedBackgroundLayer image={areaData.backgroundFixedImage}>
        {areaData.backgroundFixedShips.map((ship) => (
          <BackgroundShip
            key={ship.verticalPosition}
            image={ship.image}
            duration={ship.duration}
            height={ship.height}
            verticalPosition={ship.verticalPosition}
            isMovingRight={ship.isMovingRight}
          />
        ))}
      </FixedBackgroundLayer>
      <SlowBackgroundLayer
        image={areaData.backgroundSlowImage}
        position={position}
      >
        {areaData.backgroundSlowShips.map((ship) => (
          <BackgroundShip
            key={ship.verticalPosition}
            image={ship.image}
            duration={ship.duration}
            height={ship.height}
            verticalPosition={ship.verticalPosition}
            isMovingRight={ship.isMovingRight}
          />
        ))}
      </SlowBackgroundLayer>
      <MovingBackgroundLayer
        image={areaData.backgroundMovingImage}
        position={position}
      >
        {areaData.backgroundMovingShips.map((ship) => (
          <BackgroundShip
            key={ship.verticalPosition}
            image={ship.image}
            duration={ship.duration}
            height={ship.height}
            verticalPosition={ship.verticalPosition}
            isMovingRight={ship.isMovingRight}
          />
        ))}
      </MovingBackgroundLayer>
      <GameLayer position={position}>
        {areaData.npcs.map((npc) => (
          <NPC key={npc} slug={npc} />
        ))}
        {areaData.buildings.map((building) => (
          <Building key={building} slug={building} />
        ))}
        <FightBackground isFightMode={isFightMode} />
        {areaData.mobs.map((mob) => (
          <Mob key={mob} slug={mob} />
        ))}
        {onlinePlayers
          .filter(
            (player) =>
              player.userName !== userName && player.data.location.area === area
          )
          .map((player) => {
            return <OnlinePlayer key={player.userName} player={player} />;
          })}
      </GameLayer>
      <AvatarLayer>{children}</AvatarLayer>
      <ForegroundLayer image={areaData.foregroundImage} position={position} />
    </WorldWrapper>
  );
}
