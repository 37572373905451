import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import healthImage from "assets/health.svg";
import Bar from "components/base/Bar";
import Damage from "components/base/Damage";
import useDialog from "components/hooks/useDialog";
import {
  floatAnimation,
  mobAttackAnimation,
  mobDamagedAnimation,
  mobDodgeAnimation,
  mobLoseAnimation,
  mobNullifyAnimation,
  mobShieldsAnimation,
  mobTargetingSystemAnimation,
  reactorCoreAnimation,
  weakenedAnimation,
} from "components/styles/animations";
import {
  SKILL_ANIMATIONS,
  SKILL_MOB_SHIELDS_ANIMATIONS,
  SKILL_MOB_SHIP_ANIMATIONS,
} from "components/styles/skills";
import {
  getLastWeakenedPartColor,
  getMobData,
  getMobShipDialog,
} from "libs/fight";
import { getSkillAnimation } from "libs/skill";
import { calculateInitialMobStats, hasAnimation } from "libs/stats";
import {
  endFightLose,
  endFightWin,
  previewFight,
  startMobDialog,
} from "redux/actions";
import { getCharacter, getFight, getShipUpgrades } from "redux/selectors";
import { FIGHT_DIALOG_STATUSES } from "utils/constants";
import BattleRating from "./BattleRating";
import Dialogs from "./Dialogs";
import Tooltip, { TooltipDesc, TooltipName, Trigger } from "./Tooltip";

interface MobProps {
  slug: string;
}

interface MobWrapperProps {
  position: number;
  heightPercentage: number;
}

interface MobImageWrapperProps {
  $animations: string[];
}

interface MobShipWeaponsProps {
  $position: {
    x: number;
    y: number;
  };
  $animations: string[];
  $animationSpeed: number;
}

interface MobShipShieldsWrapperProps {
  $animations: string[];
}

interface MobShipShieldsProps {
  $animationSpeed: number;
}

interface MobShipThrustersProps {
  $position: {
    x: number;
    y: number;
  };
  $animationSpeed: number;
}

interface MobShipTargetingSystemProps {
  $position: {
    x: number;
    y: number;
  };
  $animationSpeed: number;
}

interface MobShipReactorCoreProps {
  $animationSpeed: number;
}

interface WeakenedPartProps {
  $animations: string[];
  $color: string;
}

interface SkillAnimationProps {
  $animations: string[];
}

const MobWrapper = styled.div<MobWrapperProps>`
  position: absolute;
  left: ${(props) => props.position * 0.2}%;
  height: ${(props) => props.heightPercentage}%;
  z-index: 5;
`;

const MainMobWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  animation: ${floatAnimation} 3s ease-in-out infinite;

  &:hover {
    filter: brightness(120%);
  }
`;

const attackMixin = css<MobShipWeaponsProps>`
  animation: ${mobAttackAnimation} ${(props) => props.$animationSpeed}ms
    ease-in-out forwards;
  transform-origin: bottom left;
`;

const damagedMixin = css`
  animation: ${mobDamagedAnimation} 300ms ease-in-out forwards;
  transform-origin: bottom center;
`;

const weakenMixin = css`
  animation: ${weakenedAnimation} 500ms ease-out forwards;
  transform-origin: center center;
`;

const nullifyMixin = css`
  animation: ${mobNullifyAnimation} 500ms linear forwards;
  transform-origin: center center;
`;

const dodgeMixin = css`
  animation: ${mobDodgeAnimation} 300ms ease-in-out forwards;
  transform-origin: bottom center;
`;

const loseMixin = css`
  animation: ${mobLoseAnimation} 3000ms ease-in forwards;
  transform-origin: bottom center;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 80%;
  color: white;
  z-index: 2;
`;

const NameWrapper = styled.div`
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  gap: 10px;
`;

const Name = styled.div`
  color: #dddddd;
`;

const IconsWrapper = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const LevelInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
  color: #fff648;
`;

const LevelIcon = styled.div``;

const LevelValue = styled.div``;

const DialogWrapper = styled.div`
  width: 160%;
  margin-bottom: 10px;
`;

const MobImageWrapper = styled.div<MobImageWrapperProps>`
  height: 100%;
  position: relative;
  aspect-ratio: 1 / 1;
  user-select: none;

  ${(props) =>
    hasAnimation(props.$animations, "damaged") ? damagedMixin : ""};
  ${(props) => (hasAnimation(props.$animations, "dodge") ? dodgeMixin : "")};
  ${(props) => (hasAnimation(props.$animations, "lose") ? loseMixin : "")};
  ${(props) => getSkillAnimation(SKILL_MOB_SHIP_ANIMATIONS, props.$animations)}
`;

const MobImage = styled.img`
  height: 100%;
`;

const MobShipWeapons = styled.img<MobShipWeaponsProps>`
  height: 4%;
  position: absolute;
  top: ${(props) => props.$position.y}%;
  right: ${(props) => props.$position.x}%;
  transform: translate3d(0, -50%, 0) scaleX(-1);
  transform-origin: center center;
  z-index: -1;
  opacity: 0;
  ${(props) => (hasAnimation(props.$animations, "attack") ? attackMixin : "")};
  pointer-events: none;
`;

const MobShipShieldsWrapper = styled.div<MobShipShieldsWrapperProps>`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${(props) =>
    hasAnimation(props.$animations, "nullify") ? nullifyMixin : ""};
  ${(props) =>
    getSkillAnimation(SKILL_MOB_SHIELDS_ANIMATIONS, props.$animations)}
  transition: all 200ms ease-in-out;
  pointer-events: none;
`;

const MobShipShields = styled.img<MobShipShieldsProps>`
  height: 100%;
  width: 100%;
  animation: ${mobShieldsAnimation} ${(props) => props.$animationSpeed}ms linear
    infinite;
  z-index: 1;
`;

const MobShipThrusters = styled.img<MobShipThrustersProps>`
  height: 6%;
  position: absolute;
  top: ${(props) => props.$position.y}%;
  left: calc(100% - ${(props) => props.$position.x}%);
  transform: translate3d(0, -50%, 0) scaleX(-1);
  transform-origin: center center;
  z-index: 0;
  pointer-events: none;
`;

const MobShipTargetingSystem = styled.img<MobShipTargetingSystemProps>`
  width: 60%;
  position: absolute;
  top: ${(props) => props.$position.y}%;
  right: ${(props) => props.$position.x}%;
  transform: translate3d(0, -50%, 0);
  transform-origin: center center;
  animation: ${mobTargetingSystemAnimation}
    ${(props) => props.$animationSpeed}ms linear infinite;
  z-index: 1;
  pointer-events: none;
`;

const MobShipReactorCore = styled.img<MobShipReactorCoreProps>`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${reactorCoreAnimation} ${(props) => props.$animationSpeed}ms
    ease-out infinite;
  z-index: -1;
  pointer-events: none;
`;

const MobShipReactorCoreSecond = styled(MobShipReactorCore)`
  animation-delay: ${(props) => props.$animationSpeed / 2}ms;
  pointer-events: none;
`;

const MobShipAnimation = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transform: scaleX(-1);
`;

const WeakenedPart = styled.div<WeakenedPartProps>`
  width: 120px;
  height: 120px;
  opacity: 0;
  border: 8px ${(props) => props.$color} none;
  border-left-style: solid;
  border-radius: 50%;
  filter: brightness(120%);
  ${(props) => (hasAnimation(props.$animations, "weaken") ? weakenMixin : "")};
`;

const PilotSkills = styled.div<SkillAnimationProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 4;
  pointer-events: none;
  box-sizing: border-box;
  ${(props) => getSkillAnimation(SKILL_ANIMATIONS, props.$animations)}
`;

export default function Mob({ slug }: MobProps) {
  const dispatch = useDispatch();
  const {
    data: { battleRatings },
  } = useSelector(getCharacter);
  const {
    name,
    level,
    images,
    dialogs,
    position,
    heightPercentage,
    installedUpgrades,
    partPositions,
  } = getMobData(slug);
  const { status, opponent } = useSelector(getFight);

  const shipUpgrades = getShipUpgrades(installedUpgrades);

  const { derivedStats } = calculateInitialMobStats(slug);

  // Set up health from max health
  const maxHealth = derivedStats.total.complete.maxHealth;

  const defaultMobState = {
    health: maxHealth,
    damage: [],
    animations: [],
    derivedStats,
  };

  // Fights should only affect the actual mob that's fighting
  const isActiveMob = slug === opponent.slug;

  // Mob dialog logic
  const activeDialog = getMobShipDialog(dialogs, status);
  const isDialogActive = !!isActiveMob && !!activeDialog;
  const hasDialogNextAction = ["openingDialog", "loseDialog"].includes(status);

  const { currentStep, currentDialog, hasAction, onNextStep } = useDialog(
    activeDialog || [],
    true,
    hasDialogNextAction
  );

  const mobState =
    isActiveMob && ["fighting", "winResults", "loseResults"].includes(status)
      ? opponent
      : defaultMobState;

  const handleClickMob = () => {
    if (
      status === "notFighting" ||
      (FIGHT_DIALOG_STATUSES.includes(status) && !isActiveMob)
    ) {
      dispatch(startMobDialog(slug));
    }
    if (isActiveMob) {
      if (status === "openingDialog") {
        dispatch(previewFight());
      }
      if (status === "fighting" && !!opponent.isTutorial) {
        onNextStep();
      }
      if (status === "winDialog") {
        dispatch(endFightWin());
      }
      if (status === "loseDialog") {
        dispatch(endFightLose());
      }
    }
  };

  const weakenedPartColor = getLastWeakenedPartColor(mobState.damage);

  return (
    <MobWrapper position={position} heightPercentage={heightPercentage}>
      <MainMobWrapper onClick={handleClickMob}>
        <InfoWrapper>
          <Damage damageList={mobState.damage} />
          <NameWrapper>
            <Name>{name}</Name>
            <IconsWrapper>
              <Trigger>
                <LevelInfo>
                  <LevelIcon>✧</LevelIcon>
                  <LevelValue>{level}</LevelValue>
                </LevelInfo>
                <Tooltip>
                  <TooltipName>{`${name}'s Level`}</TooltipName>
                  <TooltipDesc>{`${name} is a level ${level} opponent. You probably shouldn't take on ships that have a much higher level than you.`}</TooltipDesc>
                </Tooltip>
              </Trigger>
              {!!battleRatings[slug] && (
                <BattleRating rating={battleRatings[slug]} iconOnly />
              )}
            </IconsWrapper>
          </NameWrapper>
          {!!isDialogActive && (
            <DialogWrapper>
              <Dialogs
                avatar={images.pilot}
                currentStep={currentStep}
                currentDialog={currentDialog}
                hasAction={hasAction}
              />
            </DialogWrapper>
          )}
          <Bar
            icon={healthImage}
            number={mobState.health}
            maxNumber={mobState.derivedStats.current.complete.maxHealth}
            color="#BC5B5B"
            name={`${name}'s Ship Health`}
            description={`This is how much health ${name} has in battle.`}
          />
        </InfoWrapper>
        <MobImageWrapper $animations={mobState.animations}>
          <MobShipAnimation>
            <WeakenedPart
              $animations={mobState.animations}
              $color={weakenedPartColor}
            />
            <PilotSkills $animations={mobState.animations} />
          </MobShipAnimation>
          <MobImage src={images.ship} />
          <MobShipWeapons
            src={shipUpgrades.weapons.image}
            $position={partPositions.weapons}
            $animations={mobState.animations}
            $animationSpeed={shipUpgrades.weapons.animationSpeed}
          />
          <MobShipThrusters
            src={shipUpgrades.thrusters.image}
            $position={partPositions.thrusters}
            $animationSpeed={shipUpgrades.thrusters.animationSpeed}
          />
          <MobShipTargetingSystem
            src={shipUpgrades.targetingSystem.image}
            $position={partPositions.targeting}
            $animationSpeed={shipUpgrades.targetingSystem.animationSpeed}
          />
          <MobShipReactorCore
            src={shipUpgrades.reactorCore.image}
            $animationSpeed={shipUpgrades.reactorCore.animationSpeed}
          />
          <MobShipReactorCoreSecond
            src={shipUpgrades.reactorCore.image}
            $animationSpeed={shipUpgrades.reactorCore.animationSpeed}
          />
          <MobShipShieldsWrapper $animations={mobState.animations}>
            <MobShipShields
              src={shipUpgrades.shields.image}
              $animationSpeed={shipUpgrades.shields.animationSpeed}
            />
          </MobShipShieldsWrapper>
        </MobImageWrapper>
      </MainMobWrapper>
    </MobWrapper>
  );
}
