import { PLAYLISTS } from "data/music";
import { PlaylistModel } from "types";

// DATA

export const getPlaylistData = (track: string): PlaylistModel => {
  return PLAYLISTS[track];
};

// MUSIC

export const getNextMusicTrack = (
  currentTrack: string,
  currentPlaylist: PlaylistModel
): string => {
  const currentIndex = currentPlaylist.indexOf(currentTrack);

  // If switched to a different playlist, just play first song of new playlist
  // Repeat playlist when ended
  let nextIndex = 0;
  if (currentIndex >= 0 && currentIndex < currentPlaylist.length - 1) {
    nextIndex = currentIndex + 1;
  }

  return currentPlaylist[nextIndex];
};

export const isTrackInPlaylist = (
  currentTrack: string,
  currentPlaylist: PlaylistModel
): boolean => {
  const isInPlaylist = currentPlaylist.includes(currentTrack);

  return isInPlaylist;
};

export const shuffleList = (array: string[]) => {
  return array
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);
};
