import { ChangelogUpdateModel } from "types";

export const ROADMAP: string[] = [
  "<strong>More planets</strong>: Planets will each focus on a particular base stat. Ex: If you want the best antimatter upgrades, you'll want to hit up the antimatter planet",
  "<strong>Mobs/drops across all levels</strong>: Right now, mobs only go up to level 20, whereas player level goes up to 50",
  "<strong>Multi-ship battles</strong>: You'll be able to build a small party of ships/pilots to fight in 2v2 battles, increasing the strategic complexity of each fight",
  "<strong>Missions</strong>: Fun story-based missions with unique rewards",
];

export const CHANGELOG: ChangelogUpdateModel[] = [
  {
    name: "Thoughts and prizes",
    date: new Date("2-9-25"),
    changes: [
      "Added better drops for Bishop mobs, so that every victory at any battle rating results in an supply/upgrade drop of some kind",
    ],
  },
  {
    name: "All The Small Things",
    date: new Date("1-5-25"),
    changes: [
      "Added hit chance and dodge chance to the stat bar tooltips at the bottom of the battle screen, for both the player and the opponent",
      "When looking at an upgrade's tooltip, there is now a comparison of an its stats against the upgrade you currently have installed",
      "Changed up the XP icon to no longer be an up arrow to add clarity",
    ],
  },
  {
    name: "Thinking Uncritically about Attacks",
    date: new Date("1-4-25"),
    changes: [
      "Battling was mixing a too much chance and variance with strategic decision making, so some big changes were made to how attacks work:",
      "Linear probability on attack damage/dodge/critical hit was removed. Previously, if you had low accuracy against a high evasion defender, you would consistently hit very low in your damage range, even if your range was very wide. This made some conceptual sense, but high firepower mobs never really hit with high damage which rendered them essentially inert opponents",
      "Critical hits were also removed. While they brought a fun element of chance, they were kind of redundant, since chance is already involved in having a high firepower with low accuracy build",
      "Dodging an attack is still based on attacker accuracy vs. defender evasion, but the formula is a lot more simple now. My math skills weren't good enough to handle exponential curves",
      "Hit chance of attacks and skills are now shown in battle tooltips",
    ],
  },
  {
    name: "Sneak peak tweaks",
    date: new Date("1-4-25"),
    changes: [
      "Bugfix: Fixed issue where a few in-battle opponent skills were reflecting the player's stats instead",
      "Add damage reduced by shields to combat logs",
    ],
  },
  {
    name: "(Re)Work Experience",
    date: new Date("12-31-24"),
    changes: [
      "Re-configured how experience works to be a fixed amount per level (1000). Experience given from mobs now depends on the level difference in a battle",
      "Fight preview overlay now shows how much experience and credits you'd gain with each battle rating",
      "Fixed issue where leveling up through an Exp Booster wouldn't let you know that you leveled up or how many medallions you gained, and would cause a weird victory screen in your next battle",
      "Upped battle rating reward multipliers, which applies to how much experience and how many credits you gain from a victory: silver is now 1.2x instead of 1.1x, gold is now 1.5x instead of 1.2x, and platinum is now 2x instead of 1.5x",
    ],
  },
  {
    name: "Long-Term Memory",
    date: new Date("12-26-24"),
    changes: [
      "Combat log now keeps all actions during a battle in a scrollable view, instead of just the latest 5",
      "Reduced Rapidfire skill to 3 minimum damage shots instead of 4",
    ],
  },
  {
    name: "Season's tweakings",
    date: new Date("12-26-24"),
    changes: [
      "On the enhance stats screen, the base stat tooltips now show how much each stat enhancement adds to the actual derived stats",
      "Decreased cost of experience boosters, they were too pricy for the amount of experience",
      "Limited the max quantity of certain items in your inventory (ex: it doesn't make sense to have two Recall Rifts). So now you can't accidentally buy too many",
      "And lastly, sorry for the terrible update title. Happy holidays to you all!",
    ],
  },
  {
    name: "Sneak peek",
    date: new Date("12-23-24"),
    changes: [
      "Added opponent skills to the in-battle screen, with recharge numbers and tooltip info, so you can better track when they might use a skill",
    ],
  },
  {
    name: "Take it to the Limit",
    date: new Date("12-22-24"),
    changes: [
      "Added actual UI for when max level (50) is reached",
      "Limited base stat enhancements to max amount (25). this was done in the UI, but not actually in the logic (oof)",
      "Implemented this changelog - so meta",
    ],
  },
  {
    name: "Mulligan!",
    date: new Date("12-19-24"),
    changes: [
      "Added the ability to switch ship and pilot up to level 5, given players were asked to make a choice on ship/pilot combo before they even knew what the game was. This can be done in the Shipworks and Pilot Guild buildings, for a small credits cost",
      "Also added Recall Rift (the item to teleport back to the city) to the main screen for easier access",
    ],
  },
  {
    name: "Getting Ratio'd",
    date: new Date("12-17-24"),
    changes: [
      "Updated Battle Rating damage ratios to be more reasonable and actually achievable through the right build and strategy. Silver changed from 3:1 to now 2:1. Gold changed from 10:1 to now 5.1 Platinum changed from 40:1 (oof) to now 15:1",
      "Added the actual current damage ratio to the Battle Rating progress bar tooltip",
      "Added the Battle Rating progress bar (final bar in this case) to the Victory overlay",
      "Thank you to Vael Victus for all the playtesting and help with Battle Rating calibrations!",
    ],
  },
  {
    name: "Makeover Montage",
    date: new Date("12-15-24"),
    changes: [
      "Updated the visuals of the 5 player ships to be a little more detailed and interesting to look at since they're on-screen 95% of the time",
    ],
  },
  {
    name: "28 Tweaks Later",
    date: new Date("12-9-24"),
    changes: [
      "Platinum is now 40:1 damage dealt vs. damage taken instead of 20:1 (Don't worry, this gets changed later, because good god this is difficult)",
      "Reduced amount of sell credits that upgrades are worth",
      "Doubled the amount of XP gained by defeating mobs",
      "Doubled the amount of credits gained by defeating mobs",
    ],
  },
  {
    name: "Loss Leader",
    date: new Date("12-7-24"),
    changes: [
      "Removed loss of XP when losing a battle, given the time cost of losing and being sent back to the main city is punishment enough",
      "Thank you to Vael Victus for the suggestion!",
    ],
  },
  {
    name: "Let's Costco it up",
    date: new Date("12-5-24"),
    changes: [
      "Implemented ability to buy or sell in 10x quantities at the Shipworks shop",
      "Added WASD keyboard controls for movement (really just AD, given the very horizontal-ness of Light Voyagers",
    ],
  },
  {
    name: "Total Recall",
    date: new Date("12-4-24"),
    changes: [
      "Added a new supply Recall Rift that allows you to instantly transport back to the main city in a planet (currently one planet) - very MUD inspired",
    ],
  },
  {
    name: "Log about combat ...log",
    date: new Date("12-3-24"),
    changes: [
      "Implemented a combat log describing every fight action, since it's hard to catch every action that happens",
      "Added a Battle Rating progress bar, letting you know what battle rating you're on track for while in battle",
      "Removed now redundant toast messages blocking the battle animations, since those are all now represented in the combat log",
      "Thank you to Dryp for the early playtesting and for the combat log idea!",
    ],
  },
  {
    name: "Checks and Balances",
    date: new Date("12-1-24"),
    changes: [
      "Added player and mob skills to Starship Analysis (fight preview) overlay",
      "Adjusted how previous highest battle rating is shown in the Starship Analysis overlay",
      "Max damage gains on base stats and upgrades reduced slightly",
      "Rapidfire skill now fires four shots, based on the ship's minimum damage stat",
      "Beefed up Resilience on Acela",
      "Critical hit chance threshold is slightly higher (more rare)",
    ],
  },
  {
    name: `"Alpha" Release!`,
    date: new Date("11-30-24"),
    changes: [
      "Core game loop is established, with enough content to playtest",
      "Mobs and drops in Bishop up to level 20",
      "Mobs can now perform same skills as players",
      "Show derived stats when hovering over ship parts bars in battle",
      "Shared on PBBG Reddit and Discord looking for early feedback",
      "Thank you to Bredkrum for the feedback to get the game to this release!",
    ],
  },
];
