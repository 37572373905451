import { useSelector } from "react-redux";
import styled from "styled-components";

import creditsImage from "assets/credits.svg";
import enhancementsImage from "assets/enhancements.svg";
import experienceImage from "assets/exp.svg";
import { getCharacter } from "redux/selectors";

import BattleRating from "components/base/BattleRating";
import {
  DataWrapper,
  Section,
  SectionContent,
  SectionContentScroll,
  SectionTitle,
} from "components/base/DataScreen";
import Status from "components/base/Status";
import Tooltip, {
  TooltipDesc,
  TooltipName,
  Trigger,
} from "components/base/Tooltip";
import { COLORS } from "utils/constants";
import {
  getAreaData,
  getPilotData,
  getPlanetData,
  getShipData,
} from "libs/stats";
import { getPlatinumBattleRatingCount } from "libs/fight";
import TooltipMedallions from "components/base/TooltipMedallions";
import { isMaxLevel } from "libs/character";

interface IconProps {
  image: string;
}

const Vitals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;

const VitalWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 2px;
`;

const VitalIcon = styled.div<IconProps>`
  height: 16px;
  width: 16px;
  mask: url(${(props) => props.image}) no-repeat center;
`;

const VitalValue = styled.div``;

const ShipWrapper = styled(VitalWrapper)`
  color: #56d2ef;
`;
const PilotWrapper = styled(VitalWrapper)`
  color: #f98562;
`;

const LevelWrapper = styled(VitalWrapper)`
  color: ${COLORS.LEVELS};
`;

const LevelIcon = styled.div``;

const ExperienceWrapper = styled(VitalWrapper)`
  color: ${COLORS.EXPERIENCE};
`;

const ExperienceIcon = styled(VitalIcon)`
  background-color: ${COLORS.EXPERIENCE};
`;

const CreditsWrapper = styled(VitalWrapper)`
  color: ${COLORS.CREDITS};
`;

const CreditsIcon = styled(VitalIcon)`
  background-color: ${COLORS.CREDITS};
`;

const MedallionsWrapper = styled(VitalWrapper)`
  color: ${COLORS.ENHANCEMENTS};
`;

const MedallionsIcon = styled(VitalIcon)`
  background-color: ${COLORS.ENHANCEMENTS};
`;

const RatingsWrapper = styled(VitalWrapper)`
  color: ${COLORS.RATINGS.PLATINUM};
`;

const RatingsIcon = styled.div``;

const AtlasWrapper = styled(VitalWrapper)`
  color: ${COLORS.LOCATION};
`;

const SectorIcon = styled.div`
  font-size: 22px;
  line-height: 16px;
`;

export default function ShipworksRepair() {
  const {
    data: {
      ship,
      pilot,
      level,
      currentLevelExp,
      nextLevelExp,
      credits,
      location,
      medallions,
      battleRatings,
    },
  } = useSelector(getCharacter);

  const { name: shipClass, description: shipDesc } = getShipData(ship);
  const { name: pilotProfession, description: pilotDesc } = getPilotData(pilot);
  const { name: planet } = getPlanetData(location.planet);
  const { name: sector } = getAreaData(location.area);

  const platinumRatings = getPlatinumBattleRatingCount(battleRatings);
  const isExpMaxedOut = isMaxLevel(level);

  return (
    <DataWrapper>
      <Section>
        <SectionTitle>Status</SectionTitle>
        <SectionContent>
          <Status />
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle>Core Data</SectionTitle>
        <SectionContentScroll>
          <Vitals>
            <Trigger>
              <InfoWrapper>
                <Name>Starship Class</Name>
                <ShipWrapper>
                  <VitalValue>{shipClass}</VitalValue>
                </ShipWrapper>
              </InfoWrapper>
              <Tooltip>
                <TooltipName>Starship: {shipClass}</TooltipName>
                <TooltipDesc>{shipDesc}</TooltipDesc>
              </Tooltip>
            </Trigger>
            <Trigger>
              <InfoWrapper>
                <Name>Pilot Discipline</Name>
                <PilotWrapper>
                  <VitalValue>{pilotProfession}</VitalValue>
                </PilotWrapper>
              </InfoWrapper>
              <Tooltip>
                <TooltipName>Pilot: {pilotProfession}</TooltipName>
                <TooltipDesc>{pilotDesc}</TooltipDesc>
              </Tooltip>
            </Trigger>
            <Trigger>
              <InfoWrapper>
                <Name>Level</Name>
                <LevelWrapper>
                  <LevelIcon>✧</LevelIcon>
                  <VitalValue>{level}</VitalValue>
                </LevelWrapper>
              </InfoWrapper>
              <Tooltip>
                <TooltipName>Level</TooltipName>
                <TooltipDesc>{`You are currently at level ${level}. Keep leveling up to strengthen your ship and learn more pilot skills!`}</TooltipDesc>
              </Tooltip>
            </Trigger>
            <Trigger>
              <InfoWrapper>
                <Name>Experience To Next Level</Name>
                <ExperienceWrapper>
                  <ExperienceIcon image={experienceImage} />
                  <VitalValue>
                    {isExpMaxedOut ? "MAX" : nextLevelExp - currentLevelExp}
                  </VitalValue>
                </ExperienceWrapper>
              </InfoWrapper>
              <Tooltip>
                <TooltipName>Experience</TooltipName>
                {isExpMaxedOut ? (
                  <TooltipDesc>
                    You're currently at max level, good on you! Maybe use the
                    opportunity to go brag a bit to other players.
                  </TooltipDesc>
                ) : (
                  <TooltipDesc>{`You only need ${
                    nextLevelExp - currentLevelExp
                  } experience to get to the next level. Gain more experience through battles to level up!`}</TooltipDesc>
                )}
              </Tooltip>
            </Trigger>
            <Trigger>
              <InfoWrapper>
                <Name>Credits</Name>
                <CreditsWrapper>
                  <CreditsIcon image={creditsImage} />
                  <VitalValue>{credits}</VitalValue>
                </CreditsWrapper>
              </InfoWrapper>
              <Tooltip>
                <TooltipName>Credits</TooltipName>
                <TooltipDesc>
                  {`You have ${credits} credits to spend on buying repairs, supplies, upgrades, and more!`}
                </TooltipDesc>
              </Tooltip>
            </Trigger>
            <Trigger>
              <InfoWrapper>
                <Name>Available Medallions</Name>
                <MedallionsWrapper>
                  <MedallionsIcon image={enhancementsImage} />
                  <VitalValue>
                    {medallions.available} / {medallions.total}
                  </VitalValue>
                </MedallionsWrapper>
              </InfoWrapper>
              <TooltipMedallions />
            </Trigger>
            <Trigger>
              <InfoWrapper>
                <Name>Platinum Battle Ratings</Name>
                <RatingsWrapper>
                  <RatingsIcon>
                    <BattleRating rating="platinum" iconOnly noTooltip />
                  </RatingsIcon>
                  <VitalValue>{platinumRatings}</VitalValue>
                </RatingsWrapper>
              </InfoWrapper>
              <Tooltip>
                <TooltipName>Platinum Battle Ratings</TooltipName>
                <TooltipDesc>{`You have earned ${platinumRatings} platinum battle ratings, by scoring dominant victories against opponents.`}</TooltipDesc>
              </Tooltip>
            </Trigger>
            <InfoWrapper>
              <Name>Current Planet</Name>
              <AtlasWrapper>
                <VitalValue>❍ {planet}</VitalValue>
              </AtlasWrapper>
            </InfoWrapper>
            <InfoWrapper>
              <Name>Sector</Name>
              <AtlasWrapper>
                <SectorIcon>⌔</SectorIcon>
                <VitalValue>{sector}</VitalValue>
              </AtlasWrapper>
            </InfoWrapper>
          </Vitals>
        </SectionContentScroll>
      </Section>
    </DataWrapper>
  );
}
