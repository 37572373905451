import {
  CharacterBaseStats,
  CharacterDerivedStats,
  DamageModel,
  PilotTypes,
  SkillModel,
  SkillSlugs,
} from "types";
import {
  CREDITS_PER_SKILL_TOKEN,
  getAvgAttackDamage,
  getAvgWeakenParts,
  getDerivedStatData,
  getPilotData,
  getPropertyData,
} from "libs/stats";
import {
  getMultiplierAttackValues,
  getNumberFromDiceRoll,
  rollDice,
} from "libs/fight";
import { SKILLS } from "data/skills";
import { FlattenSimpleInterpolation } from "styled-components";

// DATA

export const getSkillData = (skill: string): SkillModel => {
  return SKILLS[skill];
};

// SKILL DATA FETCH

export const getCharacterSkills = (
  pilot: PilotTypes,
  trainedSkills: SkillSlugs[],
  fasterRecharge: number
) => {
  const rechargeInfo = getDerivedStatData("fasterRecharge");
  const pilotData = getPilotData(pilot);

  // adjust skills to account for faster recharge stat
  const pilotSkills = pilotData.skills.map((skill) => {
    const skillInfo = getSkillData(skill);
    // Check if skill is trained by player
    const isTrained = !!skillInfo.isDefault || trainedSkills.includes(skill);
    const actualRecharge = rechargeInfo.rounder(
      skillInfo.recharge - fasterRecharge
    );

    return {
      ...skillInfo,
      recharge: Math.max(actualRecharge, 1), // Minimum recharge is 1 turn
      isTrained,
    };
  });

  return pilotSkills;
};

export const getOpponentSkills = (
  skills: SkillSlugs[],
  fasterRecharge: number
) => {
  const rechargeInfo = getDerivedStatData("fasterRecharge");

  // adjust skills to account for faster recharge stat
  const pilotSkills = skills.map((skill) => {
    const skillInfo = getSkillData(skill);
    const actualRecharge = rechargeInfo.rounder(
      skillInfo.recharge - fasterRecharge
    );

    return {
      ...skillInfo,
      recharge: Math.max(actualRecharge, 1), // Minimum recharge is 1 turn
      isTrained: true,
    };
  });

  return pilotSkills;
};

export const getChargedSkills = (
  skills: SkillModel[],
  skillsRecharge: { [key: string]: number }
) => {
  const chargedSkills = skills.filter((skill) => {
    const rechargeTurns = skillsRecharge[skill.slug];
    const isRecharging =
      typeof rechargeTurns !== "undefined" && rechargeTurns > 0;

    return !isRecharging;
  });

  return chargedSkills;
};

export const getRechargeTurns = (recharge: number) => {
  // If decimal, use that remainder to determine whether to round up or down
  const remainder = recharge % 1;

  const diceRoll = rollDice();
  const shouldRoundDown = diceRoll > remainder;

  const turns = shouldRoundDown ? Math.floor(recharge) : Math.ceil(recharge);

  return turns;
};

// SKILL TRAINING

export const getSkillsMedallionsSpent = (
  trainedSkills: SkillSlugs[]
): number => {
  const propertyInfo = getPropertyData("medallions");

  let totalTokensSpent = 0;

  trainedSkills.forEach((skill) => {
    const skillInfo = getSkillData(skill);
    totalTokensSpent += skillInfo.medallions;
  });

  return propertyInfo.rounder(totalTokensSpent);
};

export const getSkillsResetCost = (medallionsSpent: number): number => {
  return Math.ceil(medallionsSpent * CREDITS_PER_SKILL_TOKEN);
};

// INDIVIDUAL SKILLS

export const getShieldBreakerDamage = (stats: CharacterDerivedStats) => {
  const SHIELD_BREAKER_MULTIPLIER = 2;

  // Get double the max weaken stats
  const initialDamage = 0;
  const initialWeakenParts = SHIELD_BREAKER_MULTIPLIER * stats.maxWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getScattershotDamage = (stats: CharacterDerivedStats) => {
  const SCATTERSHOT_MULTIPLIER = 3;

  // Get double the max weaken stats
  const initialDamage = 0;
  const initialWeakenParts = SCATTERSHOT_MULTIPLIER * stats.maxWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getPowerSurgeDamage = (
  stats: CharacterDerivedStats,
  defenderDamages: DamageModel[],
  skillsValues: { [key: string]: number }
) => {
  const totalDamage = defenderDamages.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damage,
    0
  );
  // Only allow new deflected damage to build up in next attack
  const usedDamage = skillsValues.powerSurge || 0;

  const minAttackDamage = 0;
  const initialMaxDamage = Math.max(totalDamage - usedDamage, 0);
  const initialMaxWeakenParts = 0;

  // Get max attack damage with energy multiplier in mind, for the description values
  const { attackDamage: maxAttackDamage } = getMultiplierAttackValues(
    initialMaxDamage,
    initialMaxWeakenParts,
    stats.energyMultiplier
  );

  // Roll dice to determine efficacy of damage + weaken parts
  const diceRoll = rollDice();

  const actualAttackDamage = getNumberFromDiceRoll(
    minAttackDamage,
    initialMaxDamage,
    diceRoll
  );

  const initialDamage = actualAttackDamage;
  const initialWeakenParts = initialMaxWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
    maxAttackDamage,
    totalDamage,
  };
};

export const getRapidFireDamage = (stats: CharacterDerivedStats) => {
  const initialDamage = stats.minAttackDamage;
  const initialWeakenParts = stats.minWeakenParts;
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getHyperShiftDamage = (
  stats: CharacterDerivedStats,
  nextConsecutiveTurns: number
) => {
  // Double for each next consecutive turn
  const avgDamage = getAvgAttackDamage(
    stats.minAttackDamage,
    stats.maxAttackDamage
  );
  const initialDamage = avgDamage * 2 ** nextConsecutiveTurns;
  const initialWeakenParts = 0;

  // Get damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getPhantomStrikeDamage = (stats: CharacterDerivedStats) => {
  // Avg damage
  const initialDamage = getAvgAttackDamage(
    stats.minAttackDamage,
    stats.maxAttackDamage
  );
  const initialWeakenParts = 0;

  // Get damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getEmergencyRepairHealth = (stats: CharacterDerivedStats) => {
  const healthInfo = getDerivedStatData("maxHealth");

  const INITIAL_REPAIR_PERCENTAGE = 0.25;

  const healPercentage =
    INITIAL_REPAIR_PERCENTAGE * (1 + stats.energyMultiplier);

  const healAmount = stats.maxHealth * healPercentage;

  return {
    healAmount: healthInfo.rounder(healAmount),
    healPercentage,
  };
};

export const getShieldRestoreAmount = (
  stats: CharacterDerivedStats,
  totalBaseStats: CharacterBaseStats
) => {
  const weakenPartsInfo = getDerivedStatData("maxWeakenParts");

  const INITIAL_RESTORE_PERCENTAGE = 0.25;

  const restorePercentage =
    INITIAL_RESTORE_PERCENTAGE * (1 + stats.energyMultiplier);

  const restoreAmount = totalBaseStats.resilience * restorePercentage;

  return {
    restoreAmount: weakenPartsInfo.rounder(restoreAmount),
    restorePercentage,
  };
};

export const getShieldStormDamage = (
  stats: CharacterDerivedStats,
  defenderDamages: DamageModel[],
  skillsValues: { [key: string]: number }
) => {
  const totalDamageReduced = defenderDamages.reduce(
    (sum: number, damageData: DamageModel) => sum + damageData.damageReduced,
    0
  );
  // Only allow new deflected damage to build up in next attack
  const usedDamageReduced = skillsValues.shieldStorm || 0;
  const damageReduced = Math.max(totalDamageReduced - usedDamageReduced, 0);

  const initialDamage = damageReduced;
  const initialWeakenParts = 0;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
    totalDamageReduced,
  };
};

export const getEnergyBlastDamage = (
  stats: CharacterDerivedStats,
  currentBaseStats: CharacterBaseStats
) => {
  const ENERGY_BLAST_MULTIPLIER = 5;

  const initialDamage = ENERGY_BLAST_MULTIPLIER * currentBaseStats.energy;
  const initialWeakenParts = 0;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getReactorOverloadDamage = (
  stats: CharacterDerivedStats,
  currentBaseStats: CharacterBaseStats
) => {
  const ENERGY_BLAST_MULTIPLIER = 10;

  const initialDamage = ENERGY_BLAST_MULTIPLIER * currentBaseStats.energy;
  const initialWeakenParts = 0;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getShieldBypassDamage = (
  stats: CharacterDerivedStats,
  currentBaseStats: CharacterBaseStats
) => {
  const ENERGY_BLAST_MULTIPLIER = 2;

  const initialDamage = ENERGY_BLAST_MULTIPLIER * currentBaseStats.energy;
  const initialWeakenParts = 0;

  // Get damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getSystemsDecayDamage = (stats: CharacterDerivedStats) => {
  const SYSTEMS_DECAY_MULTIPLIER = 1;

  const initialDamage = 0;
  const initialWeakenParts = SYSTEMS_DECAY_MULTIPLIER;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getPressurePointDamage = (
  stats: CharacterDerivedStats,
  skillsValues: { [key: string]: number }
) => {
  const PRESSURE_POINT_MULTIPLIER_PER_USE = 2;
  const MAX_DOUBLING_TIMES = 4;

  const useCount = Math.min(
    skillsValues.pressurePoint || 0,
    MAX_DOUBLING_TIMES
  );

  // Start with at least 1 damage if min attack is 0
  const initialDamage = Math.max(stats.minAttackDamage, 1);
  const initialWeakenParts = 0;

  // Double the damage based on how many time it's been used
  const doubledDamage =
    initialDamage * PRESSURE_POINT_MULTIPLIER_PER_USE ** useCount;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    doubledDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getEagleEyeDamage = (stats: CharacterDerivedStats) => {
  const initialDamage = stats.maxAttackDamage;
  const initialWeakenParts = stats.maxWeakenParts;

  // Get max hit damage with antimatter boost multiplier
  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  return {
    attackDamage,
    attackWeakenParts,
  };
};

export const getBlastEchoDamage = (
  stats: CharacterDerivedStats,
  defenderDamages: DamageModel[]
) => {
  const BLAST_ECHO_MULTIPLIER = 3;

  const avgWeakenParts = getAvgWeakenParts(
    stats.minWeakenParts,
    stats.maxWeakenParts
  );

  // Get triple the average weaken stats
  const initialDamage = 0;
  const initialWeakenParts = BLAST_ECHO_MULTIPLIER * avgWeakenParts;

  const { attackDamage, attackWeakenParts } = getMultiplierAttackValues(
    initialDamage,
    initialWeakenParts,
    stats.energyMultiplier
  );

  // Get last weakened base stat
  let lastWeakenedBaseStat = null;
  const lastDamage = [...defenderDamages].pop();
  if (lastDamage) {
    lastWeakenedBaseStat = lastDamage.baseStatWeakened;
  }

  return {
    attackDamage,
    attackWeakenParts,
    lastWeakenedBaseStat,
  };
};

// ANIMATIONS

export const getSkillAnimation = (
  skillAnimations: {
    [key: string]: FlattenSimpleInterpolation;
  },
  activeAnimations: string[] = []
) => {
  const skill = Object.keys(skillAnimations).find((key) => {
    return activeAnimations.includes(key);
  });
  if (skill) {
    return skillAnimations[skill];
  } else {
    return null;
  }
};
